<template>
  <div class="gmb-qa" @scroll.passive="onScroll($event.srcElement, $refs.fixedElement.$el)">
    <screen-header
      class="gmb-qa__header"
      :title="$t('gmbQa.title')"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
      ref="fixedElement"
    >
      <skeleton-icon v-if="isLoading" margin-left="16px" width="44px" height="44px" />
      <v-popover
        placement="bottom-end"
        :autoHide="true"
        container=".gmb-qa__header"
        popoverInnerClass="popover-inner no-overflow"
      >
        <ui-button
          class="gmb-qa__header__cta"
          button="primary"
          variant="data"
          icon="store"
          :label="currentAccount.accountName"
          :mobile-toggle="true"
          v-if="accounts.length > 1 && currentAccount"
        />
        <template slot="popover">
          <div class="gmb-qa__header__popover">
            <ui-dropdown
              class="gmb-qa__header__popover__dropdown"
              id="dropdown-gmb-acounts"
              v-model="modelAccount"
              :options="accounts"
              :placeholder="$t('gmbQa.accountSelector.placeholder')"
              :dropdown-label="$t('gmbQa.accountSelector.label')"
              label="accountName"
              track-by="name"
              :show-label="true"
            />
          </div>
        </template>
      </v-popover>
    </screen-header>
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <div class="gmb-qa__list">
              <ui-data-list
                :items="currentQuestionsAnswers"
                :is-loading="isLoading"
                :is-scrolling="isScrolling"
                :no-more-data="currentQuestionsAnswersPagination.nbPages === currentQuestionsAnswersPagination.page"
              >
                <template v-slot:skeleton>
                  <div class="gmb-qa__list__content">
                    <skeleton-line height="18px" width="80%" />
                    <skeleton-line height="15px" width="50%" margin-top="3px" />
                  </div>
                  <skeleton-icon margin-left="16px" />
                </template>
                <template v-slot:item="slotProps">
                  <div class="gmb-qa__list__content">
                    <div class="gmb-qa__list__content__first-line">
                      <div class="gmb-qa__list__content__first-line__name">
                        {{ date(slotProps.item.createTime) }}
                      </div>
                      <div class="gmb-qa__list__content__first-line__name">
                        {{ slotProps.item.locationName }}
                      </div>
                    </div>

                    <div class="gmb-qa__list__content__second-line">
                      <div class="gmb-qa__list__content__second-line__profile-photo-url">
                        <img :src="slotProps.item.author.profilePhotoUrl" alt="" />
                      </div>
                      <div class="gmb-qa__list__content__second-line__name">
                        {{ slotProps.item.author.displayName }} <br />
                        {{ slotProps.item.author.type }}
                      </div>
                    </div>

                    <div
                      v-tooltip="{
                        placement: 'top',
                        trigger: 'click',
                        content: slotProps.item.text,
                        offset: 3,
                      }"
                      class="gmb-qa__list__content__text"
                    >
                      {{ slotProps.item.text }}
                    </div>

                    <div class="gmb-qa__list__content__third-line">
                      <div class="gmb-qa__list__content__second-line__name">
                        {{ $t('gmbQa.list.column.totalAnswerCount') }}: {{ slotProps.item.totalAnswerCount || '0' }}
                      </div>
                      <div class="gmb-qa__list__content__second-line__name">
                        {{ $t('gmbQa.list.column.upvoteCount') }}:
                        {{ slotProps.item.upvoteCount || '0' }}
                      </div>
                    </div>
                  </div>
                </template>
              </ui-data-list>
            </div>
            <div class="gmb-qa__table">
              <ui-data-table
                :columns="columns"
                :rows="currentQuestionsAnswers"
                :total-rows="currentQuestionsAnswers.length"
                :is-loading="isLoading"
                :with-pagination="false"
                @onCellClick="goToEditQa"
              >
                <template slot="data-table-row" slot-scope="{ column, row, formattedRow }">
                  <span>
                    <div class="gmb-qa__table__block">
                      <div class="gmb-qa__table__block__content">
                        <div v-if="column.field === 'createTime'" class="gmb-qa__table__block__content__name">
                          {{ date(formattedRow[column.field]) }}
                        </div>
                        <div
                          v-else-if="column.field === 'text'"
                          v-tooltip="{
                            placement: 'top',
                            trigger: 'hover',
                            content: formattedRow.text,
                            offset: 3,
                          }"
                          class="gmb-qa__table__block__content__text"
                        >
                          {{ formattedRow.text }}
                        </div>
                        <div
                          v-else-if="column.field === 'author.profilePhotoUrl'"
                          v-tooltip="{
                            placement: 'top',
                            trigger: 'hover',
                            content: formattedRow['author.profilePhotoUrl'],
                            offset: 3,
                          }"
                          class="gmb-qa__table__block__content__profile-photo-url"
                        >
                          <img :src="formattedRow['author.profilePhotoUrl']" alt="" />
                        </div>
                        <div v-else class="gmb-qa__table__block__content__name">
                          {{ formattedRow[column.field] }}
                        </div>
                      </div>
                    </div>
                  </span>
                </template>
              </ui-data-table>
              <div class="gmb-qa__table__button">
                <ui-button
                  :label="$t('gmbQa.buttons.loadMore')"
                  v-if="currentQuestionsAnswersPagination.nbPages > currentQuestionsAnswersPagination.page"
                  @click="loadMoreGmbQa"
                  button="cta"
                  variant="data"
                />
              </div>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'GmbQa',
  components: {
    UiDataTable,
    UiDataList,
    UiDropdown,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    UiButton,
    SkeletonLine,
    SkeletonIcon,
  },
  mixins: [onScroll],
  data() {
    return {
      isLoading: false,
      isScrolling: false,
    }
  },
  async created() {
    this.isLoading = true
    await this.getAccounts({})
    if (!this.$route.params.name) {
      this.$router.replace({
        ...this.$route,
        params: { ...this.$route.params, name: this.accounts[0].name },
      })
    }
  },
  watch: {
    '$route.params.name': {
      immediate: true,
      async handler() {
        if (this.$route.params.name) {
          await this.loadGmbQa()
        }
      },
    },
    canRefreshData() {
      if (this.canRefreshData && this.hasMoreQuestionToLoad) {
        this.isScrolling = true
        this.loadMoreGmbQa()
      }
    },
  },
  computed: {
    ...mapState({
      accounts: state => state.gmb.accounts,
      currentQuestionsAnswers: state => state.qa.currentQuestionsAnswers?.data || [],
      currentQuestionsAnswersPagination: state =>
        state.qa.currentQuestionsAnswers?.pagination || { page: 1, nbPages: 1 },
    }),
    currentAccount() {
      return this.accounts.find(account => account.name === this.currentAccountName)
    },
    currentAccountName() {
      return this.$route.params.name
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('gmbQa.breadcrumb'),
          route: {
            name: 'GmbQa',
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('gmbQa.list.column.locationName'),
          field: 'locationName',
        },
        {
          label: this.$t('gmbQa.list.column.createTime'),
          field: 'createTime',
        },
        {
          label: this.$t('gmbQa.list.column.text'),
          field: 'text',
        },
        {
          label: this.$t('gmbQa.list.column.totalAnswerCount'),
          field: 'totalAnswerCount',
        },
        {
          label: this.$t('gmbQa.list.column.upvoteCount'),
          field: 'upvoteCount',
        },
        {
          label: this.$t('gmbQa.list.column.authorDisplayName'),
          field: 'author.displayName',
        },
        {
          label: this.$t('gmbQa.list.column.authorProfilePhotoUrl'),
          field: 'author.profilePhotoUrl',
        },
        {
          label: this.$t('gmbQa.list.column.authorType'),
          field: 'author.type',
        },
      ]
    },
    modelAccount: {
      get() {
        return this.currentAccountName
      },
      async set({ name }) {
        this.$router.push({ ...this.$route, params: { ...this.$route.params, name } }).catch(() => {})
      },
    },
    hasMoreQuestionToLoad() {
      const { nbPages, page } = this.currentQuestionsAnswersPagination
      return nbPages > page
    },
  },
  methods: {
    ...mapActions({
      loadQuestionsAnswers: 'qa/loadQuestionsAnswers',
      loadMoreQuestionsAnswers: 'qa/loadMoreQuestionsAnswers',
      getAccounts: 'gmb/getAccounts',
    }),
    async loadGmbQa() {
      this.isLoading = true
      try {
        await this.loadQuestionsAnswers({ account: this.currentAccountName })
      } finally {
        this.isLoading = false
      }
    },
    async loadMoreGmbQa() {
      this.isLoading = true
      try {
        await this.loadQuestionsAnswers({
          account: this.currentAccountName,
          page: this.currentQuestionsAnswersPagination.page + 1,
        })
      } finally {
        this.isLoading = false
      }
    },
    goToEditQa({ row }) {
      const { account, location, name } = row
      this.$router.push({ name: 'GmbQaEdit', params: { name: location, account, location, id: name } })
    },
    date(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.gmb-qa {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }
  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }
    &__popover {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }
      &__dropdown {
        margin-top: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }
    &__content {
      flex: 1;
      &__text {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        margin-bottom: 1rem;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
      &__first-line,
      &__third-line {
        justify-content: space-between;
      }
      &__first-line,
      &__second-line,
      &__third-line {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        &__name {
          font-weight: 600;
        }
        &__profile-photo-url {
          margin-right: 1rem;
          img {
            width: 40px;
            height: auto;
          }
        }
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: calc(100% - 3rem);
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
    }

    &__block {
      display: flex;
      align-items: center;
      &__logo {
        width: 36px;
        height: 36px;

        @media (min-width: $screen-md) {
          margin-right: $gutter-tablet;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          font-weight: 600;
        }
        &__text {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-default;
          font-style: normal;
        }
        &__profile-photo-url {
          img {
            width: 40px;
            height: auto;
          }
        }
      }
    }
  }
}
</style>
